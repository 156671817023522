import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top px-0">
        <div className="container">
          <Link to="/" className="navbar-brand me-5">
            <img
              src="https://cdn-icons-png.flaticon.com/512/4007/4007761.png"
              width="30"
              alt="Logo"
            />
          </Link>
          <div className="order-lg-3 d-flex align-items-center">
            <div>
              <div className="d-flex align-items-center">
                <Link
                  to="/"
                  className="btn btn-outline-dark shadow-sm me-1 px-4"
                >
                  Get Distributor
                </Link>
                <Link
                  to="/contact"
                  className="btn btn-primary d-none d-md-block me-2 px-4 me-lg-0"
                >
                  Contact Us
                </Link>
              </div>
            </div>
            <button
              class="navbar-toggler collapsed ms-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-default"
              aria-controls="navbar-default"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="icon-bar top-bar mt-0"></span>
              <span class="icon-bar middle-bar"></span>
              <span class="icon-bar bottom-bar"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbar-default">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link">Why Riveric ?</a>
              </li>
              <li className="nav-item">
                <a className="nav-link">Our Blogs</a>
              </li>
              <li className="nav-item">
                <a className="nav-link">Career</a>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
