function About() {
  return (
    <>
      <section class="py-8 bg-auto bg-image">
        <div class="container my-lg-8">
          <div class="row justify-content-center">
            <div class="col-12">
              <div>
                <h1 class="mb-2 display-3 fw-bold">
                  <span>About Us</span>
                </h1>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item lead">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active lead" aria-current="page">
                    About Us
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-8 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-12 mb-8">
              <h1 className="display-2 fw-bold my-2">
                Welcome to <span className="text-primary">Riveric</span>
              </h1>
              <p className="h2 lh-sm">
                At Riveric, we bring you pure, mineral-rich water sourced from
                nature’s finest springs, ensuring health, freshness, and
                sustainability in every sip.
              </p>

              <a href="#" className="my-4 btn btn-success btn-lg fs-4 px-5">
                Become Distributor
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="overflow-hidden">
        <div class="row g-2">
          <div class="col-lg-3 col-6">
            <div class="h-100">
              <img
                src="/assets/images/3.jpg"
                class="w-100 rounded-end-5 object-fit-cover"
                height="500"
              />
            </div>
          </div>
          <div class="col-lg-6 col-6 d-lg-block d-none">
            <div class="h-100">
              <img
                src="/assets/images/5.jpg"
                class="w-100 rounded-5 object-fit-cover"
                height="500"
              />
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class=" h-100">
              <img
                src="/assets/images/12.jpg"
                class="w-100 rounded-start-5 object-fit-cover"
                height="500"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-7">
        <div className="container mt-6 mb-3">
          <div className="row">
            <div className="offset-xl-2 col-xl-8 col-lg-12 col-md-12">
              <div className="mb-4 mb-xl-0 text-center">
                <span className="badge bg-success-soft rounded-pill fs-5 px-4">
                  <span className="fw-bold">Riveric</span> - Best Quality
                </span>
                <h1 className="display-2 ls-sm mt-2 fw-bold">
                  Impeccable Quality Standards
                </h1>
                <p className="mb-6 h2 px-md-8 lh-sm">
                  Every drop of Riveric has been tried and tested rigorously for
                  the safety of our loved ones.
                </p>
              </div>
            </div>
          </div>
          <img
            src="/assets/images/6.jpg"
            className="w-100 rounded-5 object-fit-cover object-position-top"
            height="400"
          />
        </div>
      </section>

      <section className="pb-8 mb-2 bg-white">
        <div className="container mb-3">
          <div className="row g-3">
            <div className="col-lg-6 col-12">
              <div className="bg-light text-center p-8 rounded-5 h-100 d-flex flex-column justify-content-center align-items-center">
                <img src="/assets/images/vision.png" width="130" />
                <p className="lead mt-4 lh-sm">
                  To become the nation’s most cherished and dependable bottled
                  water brand whilst being uncompromising about our social and
                  environmental responsibilities.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="bg-light text-center p-8 rounded-5 h-100 d-flex flex-column justify-content-center align-items-center">
                <img src="/assets/images/mission.png" width="130" />
                <p className="lead mt-4 lh-sm mb-0">
                  Symbolizing unrivaled quality, trust, and sustainable
                  innovation in the bottled water industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
