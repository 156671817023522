import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className="py-8 bg-light bg-bottle-1">
        <div className="container my-lg-5">
          <div className="row justify-content-center text-center">
            <div className="col-md-9 col-12">
              <h2 className="display-4 mb-2">Become a Riveric Distributor</h2>
              <p className="lead px-lg-8 mb-4 text-dark lh-sm">
                Join us as a distributor and bring premium, mineral-rich
                hydration to your community.
              </p>
              <p className="lead px-lg-8 lh-sm">
                Become a Riveric distributor and help provide premium,
                mineral-rich water to your community, supporting health and
                wellness.
              </p>
              <div className="d-grid d-md-block mt-6">
                <Link className="btn btn-dark mb-2 mb-md-0 me-lg-2 px-5">
                  <div className="my-1 fs-4">Become Distributor</div>
                </Link>
                <Link className="btn btn-danger px-5">
                  <div className="my-1 fs-4">About Riveric</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="pt-lg-8 pt-5 footer bg-white">
        <div className="container mt-lg-4">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="mb-4">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/4007/4007761.png"
                  width="30"
                  alt="Logo"
                />
                <div className="mt-4">
                  <p>
                    Pure. Refreshing. Sustainable. Choose Riveric - Hydration at
                    its Finest.
                  </p>
                  <p>
                    Experience the ultimate in hydration with Riveric. Pure,
                    mineral-rich water sourced sustainably for a refreshing and
                    healthier you.
                  </p>
                </div>
                <div className="fs-4 mt-4">
                  <Link className="me-3">
                    <i className="bi bi-facebook"></i>
                  </Link>
                  <Link className="me-3">
                    <i className="bi bi-twitter-x"></i>
                  </Link>
                  <Link className="me-3">
                    <i className="bi bi-instagram"></i>
                  </Link>
                  <Link className="me-3">
                    <i className="bi bi-youtube"></i>
                  </Link>
                  <Link className="me-3">
                    <i className="bi bi-envelope"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-2 col-md-3 col-6">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Company</h3>
                <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li>
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link">Why Riveric ?</Link>
                  </li>
                  <li>
                    <Link className="nav-link">Our Blogs</Link>
                  </li>
                  <li>
                    <Link className="nav-link">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact" className="nav-link">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Support</h3>
                <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li>
                    <Link className="nav-link">Help & Support</Link>
                  </li>
                  <li>
                    <Link className="nav-link">Become Distributor</Link>
                  </li>
                  <li>
                    <Link className="nav-link">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link className="nav-link">FAQ’s</Link>
                  </li>
                  <li>
                    <Link className="nav-link">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link className="nav-link">Payment Policy</Link>
                  </li>
                  <li>
                    <Link className="nav-link">Cookies</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Get in touch</h3>
                <p>
                  Shivalik Shilp Iscon Cross Road, Ambli-Bopal Road, Sarkhej -
                  Gandhinagar Highway, Ahmedabad, Gujarat 380015
                </p>
                <p className="mb-1">
                  Email : <a href="#">contact@riveric.com</a>
                </p>
                <p>
                  Phone :{" "}
                  <span className="text-dark fw-semibold">
                    (+91) 123 456 789
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <section className="mt-8 bg-light">
        <div className="container">
          <div className="row align-items-center g-0 py-4">
            <div className="col-lg-6 col-12">
              <span>Copyright © 2024 Riveric</span>
            </div>
            <div className="col-lg-6 col-12 text-lg-end">
              <span>CIN : UP746511547WRIJ7887784</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
