function Home() {
  return (
    <>
      <div id="heroBanner" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="row g-0">
              <div className="col-lg-6 col-12 d-lg-block d-none">
                <img
                  src="/assets/images/5.jpg"
                  className="w-100 object-fit-cover"
                  height="500"
                />
              </div>
              <div className="col-lg-6 col-12">
                <img
                  src="/assets/images/6.jpg"
                  className="w-100 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div className="row g-0">
              <div className="col-lg-6 col-12">
                <img
                  src="/assets/images/3.jpg"
                  className="w-100 object-fit-cover"
                  height="500"
                />
              </div>
              <div className="col-lg-6 col-12 d-lg-block d-none">
                <img
                  src="/assets/images/1.jpg"
                  className="w-100 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#heroBanner"
          data-bs-slide="prev"
        >
          <div className="button-md-circle">
            <i className="bi bi-chevron-left display-5 text-dark"></i>
          </div>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#heroBanner"
          data-bs-slide="next"
        >
          <div className="button-md-circle">
            <i className="bi bi-chevron-right display-5 text-dark"></i>
          </div>
        </button>
      </div>

      <section className="py-lg-3 py-5">
        <div className="container my-lg-8">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="me-lg-8">
                <img
                  src="/assets/images/2.jpg"
                  alt=""
                  className="rounded-5 w-100 object-fit-cover"
                  height="550"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="mb-5 mt-lg-0 mt-7">
                <span className="badge bg-success-soft rounded-pill fs-5 px-4">
                  About <span className="fw-bold">Riveric</span>
                </span>
                <h2 class="mb-2 display-4 fw-bold my-3">
                  Welcome to <span class="text-primary">Riveric</span> : Pure,
                  Mineral-Rich Water for a Healthier You
                </h2>
                <p class="fs-3 mt-3">
                  At Riveric, we deliver pure, mineral-rich water from pristine
                  springs, embodying health, purity, and sustainability in every
                  drop.
                </p>
              </div>
              <div class="d-grid d-md-flex flex-row gap-2">
                <a href="#!" class="btn btn-success btn-lg px-5">
                  Become Distributor
                </a>
                <a href="#!" class="btn btn-outline-secondary btn-lg px-5">
                  About Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8 pt-lg-0 pt-7">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-8 col-12 mx-auto">
              <div className="d-flex flex-column gap-2 text-center mb-xl-7 mb-5">
                <h2 className="mb-0 h1">Why Choose Riveric?</h2>
                <p className="mb-0">
                  We follow the best practices for manufacturing and adhere to
                  international safety, hygiene,and quality standards.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-6 col-12">
              <div className="d-flex flex-row gap-5 bg-danger-soft p-5 rounded-5">
                <div>
                  <img src="/assets/images/icons/1.png" width="80" />
                </div>
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column gap-2">
                    <h3 className="mb-0">Mineral-Rich Goodness</h3>
                    <p className="mb-0 pe-xl-4 text-dark">
                      Our water is naturally infused with essential minerals,
                      offering health benefits that rejuvenate your body and
                      mind.
                    </p>
                  </div>
                  <div className="">
                    <a
                      href="#"
                      className="icon-link icon-link-hover d-flex align-items-start"
                    >
                      <span>Know More</span>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="d-flex flex-row gap-5 bg-danger-soft p-5 rounded-5">
                <div>
                  <img src="/assets/images/icons/4.png" width="80" />
                </div>
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column gap-2">
                    <h3 className="mb-0">Pristine Sources</h3>
                    <p className="mb-0 pe-xl-4 text-dark">
                      We carefully select the best natural water sources,
                      ensuring the highest standards of purity and taste.
                    </p>
                  </div>
                  <div>
                    <a
                      href="#"
                      className="icon-link icon-link-hover d-flex align-items-start"
                    >
                      <span>Know More</span>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="d-flex flex-row gap-5 bg-danger-soft p-5 rounded-5">
                <div>
                  <img src="/assets/images/icons/2.png" width="80" />
                </div>
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column gap-2">
                    <h3 className="mb-0">State-of-the-Art Bottling</h3>
                    <p className="mb-0 pe-xl-4 text-dark">
                      From source to bottle, our advanced facilities maintain
                      the integrity of every drop.
                    </p>
                  </div>
                  <div>
                    <a
                      href="#"
                      className="icon-link icon-link-hover d-flex align-items-start"
                    >
                      <span>Know More</span>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="d-flex flex-row gap-5 bg-danger-soft p-5 rounded-5">
                <div className="">
                  <img src="/assets/images/icons/3.png" width="80" />
                </div>
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column gap-2">
                    <h3 className="mb-0">Eco-Friendly Commitment</h3>
                    <p className="mb-0 pe-xl-4 text-dark">
                      Sustainability is at the heart of everything we do. Our
                      packaging and processes are designed with the environment
                      in mind.
                    </p>
                  </div>
                  <div>
                    <a
                      href="#"
                      className="icon-link icon-link-hover d-flex align-items-start"
                    >
                      <span>Know More</span>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-7 mt-4 bg-light bg-bottle-3">
        <div className="container my-lg-5">
          <div className="row mt-7 justify-content-center">
            <div className="col-lg-10 col-md-12 col-12 text-center">
              <h2 className="mb-2 display-4 fw-bold">
                Get to Know{" "}
                <u class="text-primary">
                  <span class="text-dark">Riveric?</span>
                </u>
              </h2>
              <p className="lead">The Health Benefits of Riveric Water</p>
              <p className="lead">
                Discover the Riveric Difference today. Explore our products,
                learn about our process, and make Riveric your trusted companion
                for premium hydration and a healthier, more vibrant life.
              </p>

              <div className="mt-5 text-center">
                <a to="/about" className="btn btn-outline-dark px-8 rounded-3">
                  <div className="my-1 fs-4">Know More</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5">
        <div className="container mt-6">
          <div className="row">
            <div className="offset-xl-2 col-xl-8 col-lg-12 col-md-12">
              <div className="mb-4 mb-xl-0 text-center">
                <span className="badge bg-success-soft rounded-pill fs-5 px-4">
                  <span className="fw-bold">Riveric</span> - Best Quality
                </span>
                <h1 className="display-2 ls-sm mt-2 fw-bold">
                  Impeccable Quality Standards
                </h1>
                <p className="mb-6 h2 px-md-8 lh-sm">
                  Every drop of Riveric has been tried and tested rigorously for
                  the safety of our loved ones.
                </p>
              </div>
            </div>
          </div>
          <img
            src="/assets/images/6.jpg"
            className="w-100 rounded-5 object-fit-cover object-position-top"
            height="400"
          />
        </div>
      </section>

      <section className="pt-6 pb-8 bg-white bg-bottle-2">
        <div className="container mt-7">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="mb-4 mb-xl-0 text-center text-md-start">
                <h1 className="display-2 fw-bold mb-3 ls-sm lh-1">
                  Become a Riveric Distributor
                </h1>
                <p className="mb-4 lead">
                  Join the Riveric family and bring premium, mineral-rich water
                  to your community. As a distributor, you’ll partner with a
                  trusted brand committed to quality, health, and
                  sustainability.
                </p>
                <div className="mb-6 mb-0">
                  <ul className="list-unstyled fs-4">
                    <li className="mb-2">
                      <span className="me-2">
                        <i className="bi bi-star text-warning" />
                      </span>
                      <span className="align-text-top">
                        Eco-Friendly Commitment
                      </span>
                    </li>
                    <li className="mb-2">
                      <span className="me-2">
                        <i className="bi bi-star text-warning" />
                      </span>
                      <span className="align-text-top">Pristine Sources</span>
                    </li>
                    <li className="mb-2">
                      <span className="me-2">
                        <i className="bi bi-star text-warning" />
                      </span>
                      <span className="align-text-top">
                        State-of-the-Art Bottling
                      </span>
                    </li>
                  </ul>
                </div>
                <a
                  href="#"
                  className="glightbox btn btn-success btn-lg fs-4 px-5"
                >
                  Become Distributor
                </a>
              </div>
            </div>
            <div className="offset-xxl-1 col-xxl-5 col-lg-6 col-md-12">
              <div className="card smooth-shadow-md rounded-5 mt-lg-0 mt-4">
                <div className="card-body p-6 pb-4">
                  <div className="mb-4">
                    <h1 className="mb-0 lh-1 fw-bold h2">Become a partner</h1>
                    <p className="lead">Become a Valued Partner with Us !</p>
                  </div>
                  <form className="needs-validation" noValidate="">
                    <div className="mb-3">
                      <input
                        type="email"
                        id="courseLeadEmail"
                        className="form-control"
                        placeholder="Full Name"
                        required=""
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="email"
                        id="courseLeadEmail"
                        className="form-control"
                        placeholder="Phone No"
                        required=""
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="email"
                        id="courseLeadEmail"
                        className="form-control"
                        placeholder="Email"
                        required=""
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        id="courseLeadPassword"
                        className="form-control"
                        placeholder="GST No"
                        required=""
                      />
                    </div>
                    <div className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Business Address"
                        rows="2"
                        required=""
                      ></textarea>
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
                <div className="card-footer rounded-5 px-6 py-4">
                  <p className="mb-0 text-center">
                    By continuing you accept the{" "}
                    <a
                      href="#"
                      className="text-inherit fw-semibold text-decoration-underline"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-6">
        <div className="container mt-5">
          <div className="row pt-2">
            <div className="col-12">
              <div className="row text-center d-flex justify-content-center">
                <div className="col-lg-8 col-md-12 px-lg-8 mb-6">
                  <span className="sm-text-p text-danger fw-medium ls-md bg-primary-light px-4 py-2 rounded-pill text-secondary">
                    FAQs
                  </span>
                  <h2 class="fs-2 fw-bold mt-2 mb-0">
                    Frequently Asked{" "}
                    <u class="text-primary">
                      <span class="text-dark">Questions</span>
                    </u>
                  </h2>
                  <p className="lead">
                    Dive into our FAQs to find detailed insights, helpful tips,
                    and everything you need to know about Riveric and our
                    products.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-4 mb-lg-0 pe-lg-5">
                <img
                  src="/assets/images/3.jpg"
                  alt="featured"
                  className="w-100 rounded-5 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
            <div className="col-lg-6 pb-lg-0 pb-5 d-flex align-items-center">
              <div
                className="accordion accordion-flush w-100 pe-lg-5 pe-0 me-lg-5 me-0"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        What is Riveric?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Riveric is a dynamic platform designed to bridge the gap
                        between filmmakers and investors, empowering creators to
                        bring their projects to life with the necessary funding
                        and resources. By offering an intuitive and
                        collaborative space, Riveric facilitates connections
                        that inspire and drive innovation within the film
                        industry, helping visionary filmmakers turn concepts
                        into cinematic realities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        How Riveric curates the opportunities?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Facilitating Invoice Discounting Opportunities for
                        purchaser and seller in Media & Entertainment Industry
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        How does Riveric mitigate the risk of default?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Before listing the opportunity, Riveric signs a deal
                        with the production house for the direct transfer of
                        revenue from the OTT to the purchasers. It is the
                        leading OTTs that pay you and hence minimal worries of
                        default
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 text-center">
            <a href="#" className="btn btn-outline-dark px-8 mb-6 rounded-3">
              <div className="my-1 fs-4">More questions? Contact Us</div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
