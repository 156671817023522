function Contact() {
  return (
    <>
      <section class="py-8 bg-auto bg-image">
        <div class="container my-lg-8">
          <div class="row justify-content-center">
            <div class="col-12">
              <div>
                <h1 class="mb-2 display-3 fw-bold">
                  <span>Contact Us</span>
                </h1>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item lead">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active lead" aria-current="page">
                    Contact Us
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-4 bg-bottle-2 pb-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="py-8 me-xl-8 pe-xl-8">
                <div className="d-flex justify-content-between mb-7 align-items-center">
                  <div className="form-check form-switch theme-switch d-none">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
                <div>
                  <h1 className="display-4 fw-bold">Get In Touch.</h1>
                  <p className="lead text-dark">
                    Fill in the form to the right to get in touch with someone
                    on our team, and we will reach out shortly.
                  </p>
                  <div className="mt-8 fs-4">
                    <p className="mb-4">
                      If you are seeking support please contact us through email{" "}
                      <a href="#">support@riveric.com</a>
                    </p>
                    <p>
                      <i className="bi bi-telephone text-primary me-2" />+
                      0123-456-7890
                    </p>
                    <p>
                      <i className="bi bi-envelope-open text-primary me-2" />
                      contact@riveric.com
                    </p>
                    <p className="d-flex">
                      <i className="bi bi-geo-alt text-primary me-2" />
                      Gandhinagar Highway, Ahmedabad, Gujarat 380015
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <div className="card rounded-5 mt-6">
                <div className="card-body p-6 pb-4">
                  <div className="mb-4 bg-danger-soft p-4 rounded-4">
                    <h1 className="mb-0 lh-1 fw-bold h2">Contact Us</h1>
                    <p className="lead m-0">We’d love to hear from you !</p>
                  </div>
                  <form className="needs-validation" noValidate="">
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Full Name"
                        required=""
                      />
                    </div>
                    <div className="mb-3">
                      <div className="row g-2">
                        <div className="col-6">
                          <input
                            type="email"
                            id="courseLeadEmail"
                            className="form-control"
                            placeholder="Phone No"
                            required=""
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="email"
                            id="courseLeadEmail"
                            className="form-control"
                            placeholder="Email Address"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <select className="form-control" name="" required>
                        <option value="">How you know about us ?</option>
                        <option>Google</option>
                        <option>Facebook</option>
                        <option>Instagram</option>
                        <option>Twitter</option>
                        <option>Youtube</option>
                        <option>Website</option>
                        <option>TV</option>
                        <option>Newspaper</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Message"
                        rows="3"
                        required=""
                      ></textarea>
                    </div>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-primary rounded-4"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
                <div className="card-footer rounded-5 px-6 py-4">
                  <p className="mb-0 text-center">
                    By continuing you accept the{" "}
                    <a
                      href="#"
                      className="text-inherit fw-semibold text-decoration-underline"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-6">
        <div className="container mt-8">
          <div className="row pt-2">
            <div className="col-12">
              <div className="row text-center d-flex justify-content-center">
                <div className="col-lg-8 col-md-12 px-lg-8 mb-6">
                  <span className="sm-text-p text-danger fw-medium ls-md bg-primary-light px-4 py-2 rounded-pill text-secondary">
                    FAQs
                  </span>
                  <h2 class="fs-2 fw-bold mt-2 mb-0">
                    Frequently Asked{" "}
                    <u class="text-primary">
                      <span class="text-dark">Questions</span>
                    </u>
                  </h2>
                  <p className="lead">
                    Dive into our FAQs to find detailed insights, helpful tips,
                    and everything you need to know about Riveric and our
                    products.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-4 mb-lg-0 pe-lg-5">
                <img
                  src="/assets/images/3.jpg"
                  alt="featured"
                  className="w-100 rounded-5 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
            <div className="col-lg-6 pb-lg-0 pb-5 d-flex align-items-center">
              <div
                className="accordion accordion-flush w-100 pe-lg-5 pe-0 me-lg-5 me-0"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        What is Riveric?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Riveric is a dynamic platform designed to bridge the gap
                        between filmmakers and investors, empowering creators to
                        bring their projects to life with the necessary funding
                        and resources. By offering an intuitive and
                        collaborative space, Riveric facilitates connections
                        that inspire and drive innovation within the film
                        industry, helping visionary filmmakers turn concepts
                        into cinematic realities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        How Riveric curates the opportunities?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Facilitating Invoice Discounting Opportunities for
                        purchaser and seller in Media & Entertainment Industry
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        How does Riveric mitigate the risk of default?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Before listing the opportunity, Riveric signs a deal
                        with the production house for the direct transfer of
                        revenue from the OTT to the purchasers. It is the
                        leading OTTs that pay you and hence minimal worries of
                        default
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 text-center">
            <a href="#" className="btn btn-outline-dark px-8 mb-6 rounded-3">
              <div className="my-1 fs-4">More questions? Contact Us</div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
